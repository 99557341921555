@import url(https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800);
/** @format */
html,
body {
	height: 100%;
}
body {
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.card-image {
	-webkit-filter: brightness(80%);
	        filter: brightness(80%);
}

a .h1,
a .h2,
a .h3,
a .h4,
a .h5,
a .h6,
a .p,
a {
	color: #000;
	color: initial;
}

table.table > thead.text-muted > tr > th {
	background-color: var(--bs-body-bg);
}
table.table > tbody > tr > td {
	background-color: var(--bs-table-striped-bg);
}

* {
	box-sizing: border-box;
}
body {
	background: #f0f6f6 !important;
	min-height: 100vh;
	display: flex;
	font-weight: 400;
	font-family: "Fira Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
	font-weight: 500;
	font-family: "Fira Sans", sans-serif;
}

body,
html,
.App,
#root,
.auth-wrapper {
	width: 100%;
	height: 100%;
}

.card {
	background: #fff;
	/* box-shadow: 0 5px 10px rgba(34, 35, 58, 0.1),
		0 15px 40px rgba(34, 35, 58, 0.1); */
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
		rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.nav-item .dropdown-menu {
	background: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity));
	box-shadow: rgba(0, 0, 0, 0.16) 1px 10px 36px 0px,
		rgba(0, 0, 0, 0.16) 1px 0px 10px 1px;
}

.nav-item .dropdown-item {
	color: #ffffff;
}

.nav-item .dropdown-item:hover {
	background: rgba(var(--bs-dark-rgb), var(0.1));
}

@media (min-width: 1950px) {
	.header-container-width {
		max-width: 65% !important;
	}

	.recipes-container.container-fluid {
		max-width: 85%;
	}
	.recipes-container.container-fluid > .container-fluid {
		max-width: 90%;
	}
	.row-container.row {
		width: 100%;
	}
}

.spinner {
	display: flex;
	justify-content: center;
	align-items: center;
	background: transparent;
}

.spinner span {
	-webkit-animation: fade 1s linear 0s infinte;
	        animation: fade 1s linear 0s infinte;
	padding-right: 1rem;
}

.half-spinner {
	width: 20px;
	height: 20px;
	border: 3px solid #03fc4e;
	border-top: 3px solid transparent;
	border-radius: 50%;
	-webkit-animation: spin 0.5s linear 0s infinite;
	        animation: spin 0.5s linear 0s infinite;
}

.completed {
	color: #03fc4e;

	-webkit-animation: bigger 0.25s linear;

	        animation: bigger 0.25s linear;
}

.failed {
	color: #fff;
	padding-right: 0.5rem;

	-webkit-animation: bigger 0.25s linear;

	        animation: bigger 0.25s linear;
}

svg:hover {
	cursor: pointer;
}

@-webkit-keyframes bigger {
	from {
		-webkit-transform: scale(0);
		        transform: scale(0);
	}

	to {
		-webkit-transform: scale(2);
		        transform: scale(2);
	}
}

@keyframes bigger {
	from {
		-webkit-transform: scale(0);
		        transform: scale(0);
	}

	to {
		-webkit-transform: scale(2);
		        transform: scale(2);
	}
}

@-webkit-keyframes spin {
	from {
		-webkit-transform: rotate(0);
		        transform: rotate(0);
	}
	to {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@keyframes spin {
	from {
		-webkit-transform: rotate(0);
		        transform: rotate(0);
	}
	to {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@-webkit-keyframes fade {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fade {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

